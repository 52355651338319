<template>
  <div id="app">
    <div v-show="!playClicked" class="btn-container">
      <b-button variant="green" @click="playVideo">
        {{ $t("predvajaj") }}
      </b-button>
    </div>
    <div class="video-container" :class="{ hidden: !playClicked }" id="vc">
      <div class="embed-container" id="ec">
        <iframe
          id="video-id"
          :src="`${$t('videoSrc')}&autoplay=1`"
          frameborder="0"
          webkitAllowFullScreen
          mozallowfullscreen
          allowFullScreen
          autoplay
          muted
          allow="autoplay; fullscreen"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import Player from "@vimeo/player";

export default {
  data() {
    return {
      playClicked: false,
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    this.setVH();
    this.centerVideo();

    window.addEventListener("resize", () => {
      this.setVH();
      this.centerVideo();
    });
  },

  methods: {
    playVideo() {
      this.playClicked = true;
      var iframe = document.getElementById("video-id");
      var video = new Player(iframe);
      video.muted = false;
      video.play();
    },

    setVH() {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight}px`
      );
    },

    centerVideo() {
      var embedHeight = document.getElementById("ec").clientHeight;
      var containerHeight = document.getElementById("vc").clientHeight;
      if (embedHeight > containerHeight) {
        document.getElementById("ec").style.top = "0px";
        return;
      }
      var containerWidth = document.getElementById("vc").clientWidth;
      var containerRatio = 360 / 640;

      // get height of video
      var videoHeight = containerWidth * containerRatio;

      // position video in center
      var heightDiff = containerHeight - videoHeight;
      heightDiff = Math.round(heightDiff / 2) + "px";
      document.getElementById("ec").style.top = heightDiff;
    },
  },
};
</script>


<style>
</style>
